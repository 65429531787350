import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiEdit2, FiSmartphone, FiUsers } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class BlogDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Kins' />
                {/* Start Header Area  */}
                <div className="active-dark">
                    <Header homeLink="/" portfolioLink="/portfolio" aboutLink="/about-me" projectsLink="/projects" logo="symbol-dark" color="color-black"/>
                </div>
                {/* End Header Area  */}
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--8" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Kins mobile app</h2>
                                    <br></br>
                                    <h4 className="title theme-gradient">Bringing physical therapy to the patient</h4>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiSmartphone />Mobile app design</li>
                                        <li><FiEdit2 />UX Visual & Interaction Design</li>
                                        <li><FiUsers />UX Research</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="inner-wrapper">
                                <div className="inner">
                                    <div className="d-flex flex-wrap">
                                        <div className="col-sm-12 pb--90">
                                            <div className="thumbnail no-shadow">
                                                <img src="/assets/images/projects/kins/hero.png" alt="Feature card for Kins therapy case study."/>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-sm-12">

                                            <h3>Synopsis</h3>
                                            <p>Kins is a physical therapy provider focused on changing the way people deal with injuries by providing at-home and virtual care. They had a strong service model but not the technology and user experience to match. Alloy Collaborative and <strong><a href="www.illudesigns.com">Illu designs</a></strong>, my personal agency, aimed to change that.</p>
                                            <h3>The problem</h3>
                                            <p>Kins had a web platform with great content and service offerings, but patient engagement was lacking. They needed to improve the strength of the physical therapist and patient connection to improve communication, exercise plan adherence and overall success of patients.</p>
                                        </div>
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-4 col-sm-12">
                                            <h3>Methodology</h3>
                                            <ul className="list-style">
                                                <li>User interviews</li>
                                                <li>Contextual inquiry</li>
                                                <li>Stakeholder interviews</li>
                                                <li>Design system audit</li>
                                            </ul>
                                            <br></br>
                                            <br></br>
                                            <h3>Deliverables</h3>
                                            <ul className="list-style">
                                                <li>Dev ready mockups</li>
                                                <li>Marketing assets</li>
                                                <li>Modernized design system</li>
                                                <li>IA system map</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 pb--90">
                                        <p>We love the idea of making physical therapy more accessible by bringing it directly to the patient. Our goal was to get away from the “sterile” PT environment and provide a better connection that feels hands on while being remote. In collaboration with Alloy Collaborative we designed a mobile application that guides the patient throughout their recovery process and makes navigating daily sessions quick and efficient.</p>
                                        <br></br>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                    <img src="/assets/images/projects/kins/Home-screen.png" alt="Dashboard of Kins physical therapy mobile application."/>

                                            </div>
                                            <div className="col-lg-6">
                                                    <img src="/assets/images/projects/kins/Messaging.png" alt="In-app messaging mockup of Kins physical therapy mobile application."/>
                                            </div>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <h3>Research</h3>
                                        <p>Our work with Kins started as an audit of their website to deliver quick fixes and help them understand their current design’s problem areas.  After seeing our recommendations, Kins decided a quick fix wasn’t enough and they committed to fully tackling the issues, so they brought us back in to finish the job. With Alloy taking the research lead, we were not only able to conduct discovery research with real users, but we were able to design a solution and test it with them again. From a UX perspective this is the dream formula we strive for.</p>
                                        <blockquote className="rn-blog-quote pt--90 pb--90">We tested it, designed it, and tested it again.</blockquote>
                                        <div className="blog-single-list-wrapper d-flex flex-wrap">
                                            <div className="col-lg-12 pb--90">
                                                <div className="no-shadow">
                                                    <img src="/assets/images/projects/kins/prototype.png" alt="Feature card for Kins therapy case study."/>
                                                </div>
                                            </div>
                                            <div className="content col-lg-12 pb--90">
                                                <h3>Usability testing</h3>
                                                <p>Alloy collaborative was able to interview real people, both current users and users who were unfamiliar with the product. These users provided valuable feedback both on Kins original website to show where it could go, and during the redesign phase to ensure we were going the right direction. Every opportunity to put something in front of users allows you to truly build the product that meets people's needs. </p>
                                            </div>
                                            <div className="content col-lg-12">
                                                <h3>Interaction & visual design</h3>
                                                <p>The overarching goal of the design process was to provide the hands-on guidance and support a patient would receive real life. We also focused heavily on making sure the flow of starting and completing a session was not bogged down by too many interactions or unneeded information.</p>
                                            </div>
                                            <div className="row pb--90">
                                                <div className="col-lg-4">
                                                        <img src="/assets/images/projects/kins/progress-v2.jpg" alt="Dashboard of Kins physical therapy mobile application."/>

                                                </div>
                                                <div className="col-lg-4">
                                                        <img src="/assets/images/projects/kins/video-call.jpg" alt="In-app messaging mockup of Kins physical therapy mobile application."/>
                                                </div>
                                                <div className="col-lg-4">
                                                        <img src="/assets/images/projects/kins/schedule.jpg" alt="In-app messaging mockup of Kins physical therapy mobile application."/>
                                                </div>
                                            </div>
                                            <div className="content col-lg-12">
                                                    <p>Our goal with this application design was to make this a tool to enable the best possible outcome through physical therapy. Our initial research and ideation showed that communication, feedback, and just the right amount of information at the right time. The goal was not to increase engagement with the application beyond what was absoultely necessary for the success of the user. Creating specialized applications sometimes means getting technology out of the way as much as possible to enhance individual connections.</p>
                                                    <p>We did this by stripping down features and creating a honed experience focused on connection. The dashboard was designed to serve you the most pertinent task to be completed. Whether that was logging exercises or joining a remote session. This application was meant to accelerate the path to communication while retaining key information to help the patient along their journey to better health.
                                                    </p>
                                            </div>
                                            <div className="content col-lg-12 pb--90">
                                                <h3>Conclusion</h3>
                                                <p>Our approach to finding the best design solution is to focus on the problem and strip away everything unnecessary. This project reiterated that exact concept, limiting interactions and choices throughout the application lead to an enhanced patient experience that focused on delivering just the right amount of information.</p>
                                                <blockquote className="rn-blog-quote pt--90 pb--90">It&#39;s easy to over design something, it&#39;s difficult to design just enough.</blockquote>
                                                <p>In the end we feel we came out with a seemingly simple solution, because that is exactly how it should feel to users. This basis will allow us a great deal of flexibility as we iterate on the product into the future.</p>
                                                <h3>What we learned & next steps</h3>
                                                <p>Something we took away from this project was how important earlier interaction with developers would have been. Working alongside them earlier in the design process would have helped solve development issues sooner or avoided them all together. </p>
                                                <p>Kins isn&#39;t done yet, they still have a great number of feature concepts they hope to implement and we made sure to build a solid design foundation that&#39;s easy to add on to. Our next steps in our relationship with kins is to look into the validation of these new features, help build them out, and continue as design support. </p>
                                                <p>
                                                </p>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="service-area pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                <h2>Check out my other projects.</h2>
                                    <a className="rn-button-style--2 btn-solid" href="/portfolio"><span>Back to projects</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer homeLink="/" portfolioLink="/portfolio" aboutLink="/about-me" />

            </React.Fragment>
        )
    }
}
export default BlogDetails;