import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/Header";
import Footer from "../component/footer/Footer";
import TabTwo from "../elements/tab/TabTwo";
import PortfolioList from "../elements/portfolio/PortfolioList";
import ProjectsContent from "../elements/projects/ProjectsContent";



const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Nice to meet you',
        description: '',
        buttonText: 'Learn more',
        buttonLink: '#about'
    }
]
const PortfolioLanding = () => {
    let title = 'About me',
        description = 'I lead user-centered design matching business objectives to elevate both. Working with and adapting business plans to meet the users where they define their experience.';
    const PostList = ProjectsContent.slice(0 , 3);
    return (
        
        <div className="active-dark">
            <Helmet pageTitle="Home" />

            <HeaderThree homeLink="/" portfolioLink="/portfolio" aboutLink="/about-me" logo="symbol-dark" color="color-black"/>
            {/* Start Slider Area   */}
            <div id="home" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            <h1 className="title">I’m Per Kvanbeck, <br/>
                                            <TextLoop>
                                                <span> product designer,</span>
                                                <span> design leader,</span>
                                                <span> creative.</span>
                                            </TextLoop>{" "}
                                            </h1>
                                            <h3>Based in Minneapolis.</h3>
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            <br></br>
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */}

            {/* Start Portfolio Area */}
            <div id="portfolio" className="fix">
                <div className="portfolio-area ptb--120 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">User experience work</h2>
                                        <p>Check out examples of the product design and <br></br>research projects I've worked on recently.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="3" />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/portfolio"><span>View More</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}

            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-8.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* End About Area */}

            {/* Start Dev Projects Area
            <div id="dev-projects" className="fix">
                <div className="blog-area ptb--120 bg_color--5">
                    <div className="blog-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">My Latest Dev Projects</h2>
                                        <p>See little side projects I use to help me better learn the ins and outs of react development.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <ProjectsList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="3" />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/projects"><span>View More</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            End Dev Projects Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            <Footer homeLink="/" portfolioLink="/portfolio" aboutLink="/about-me" />
        </div>
    )
}

export default PortfolioLanding;
