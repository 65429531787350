import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import { FiBook , FiUsers , FiUmbrella ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


const ExperienceList = [
    {
        icon: <FiUsers />,
        school: 'Prime Digital Academy',
        title: 'UX Design Certificate',
        date: '2018',
        description: 'UX Design Bootcamp'
    },
    {
        icon: <FiBook />,
        school: 'Augsburg University',
        title: 'B.A. Psychology',
        date: '2007 - 2011',
        description: 'Psi Chi Honor member and Augsburg President\'s Club'
    },
    {
        icon: <FiUmbrella />,
        school: 'University of Auckland',
        title: 'Study Abroad',
        date: '2009-2010',
        description: 'There and back again'
    }
]

const JobList = [
    {
        logo: '../assets/images/experience/illu-03.png',
        company: 'Illu Designs',
        title: 'Founder',
        date: '2021 - Present',
        link: 'https://www.illudesigns.com'
    },
    {
        logo: '../assets/images/experience/foundry.jpg',
        company: 'Foundry',
        title: 'Product Designer',
        date: '2020 - 2021',
        link: 'https://www.foundrymakes.com/'
    },
    {
        logo: '../assets/images/experience/foodsby.png',
        company: 'Foodsby',
        title: 'Product Designer',
        date: '2019 - 2020',
        link: 'https://www.foodsby.com'
    },
    {
        logo: '../assets/images/experience/3m.png',
        company: '3M',
        title: 'Senior Visual UX Designer',
        date: '2018-2019',
        link: 'https://www.3m.com'
    }
]

const ReferenceList = [
    {
        logo: '../assets/images/experience/prime.png',
        name: 'Ange Wang',
        company: 'Prime Digital Academy',
        title: 'Director of UX Instruction',
        email: 'ange@primeacademy.io'
    },
    {
        logo: '../assets/images/experience/spark.png',
        name: 'Briana Como',
        company: 'Spark, CRF USA',
        title: 'Principal Product Designer',
        email: 'briana.como@gmail.com'
    },
    {
        logo: '../assets/images/experience/3m.png',
        name: 'Ivan Maramis',
        company: '3M',
        title: 'Lead Interaction Designer',
        email: 'imaramis@mmm.com'
    }
]
class Experience extends Component{
    render(){
        let title = 'About',
        description = 'My grandmother once told me, "Stay interested because my friends who don\'t, don\'t last long." I have taken this to heart and poured my interest into every subject I approach. From making short films and designing posters to bouldering and beer making. Though nothing tops my fascination and desire to create holistic design solutions for users. From discovery and ideation to prototyping and high fidelity UI design, I work to elevate every experience beyond functional to fantastic.';
        return(
            <React.Fragment>
                <PageHelmet pageTitle='About me' />
                {/* Start Header Area  */}
                <div className="active-dark">
                    <Header homeLink="/" portfolioLink="/portfolio" aboutLink="/about-me" projectsLink="/projects" logo="symbol-dark" color="color-black"/>
                </div>
                {/* End Header Area  */}

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'About me'}   />
                {/* End Breadcrump Area */}
                <source/>
                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-8.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">My goal</h3>
                                                    <p>To work with and learn from incredibly talented people.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Why me?</h3>
                                                    <p>My iterative path to UX provides me with a very unique set of skills.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br><br></br>
                                    <a className="rn-button-style--2 btn-solid" href="https://www.dropbox.com/s/5xef52pw6frjmdd/per-kvanbeck-resume%282023%29.pdf?dl=0">
                                        <span>Download resume</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Education Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Education</h2>
                                    <p>The institutions that have guided me to Product design.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ExperienceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3>{val.school}</h3>
                                                <h3 className="title">{val.title}</h3>
                                                <h4>{val.date}</h4>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Education Area */}

                {/* Start Jobs Area */}
                <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Jobs</h2>
                                    <p>My most recent roles in Product design and UX Visual design.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {JobList.map( (val , i) => (
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="service service__style--2">
                                        <div>
                                            <img className="co-logo" src={val.logo}/>
                                        </div>
                                        <div className="content">
                                            <h3>{val.company}</h3>
                                            <h3 className="title">{val.title}</h3>
                                            <h4>{val.date}</h4>
                                            <a href={val.link}>
                                                <p>View website</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Jobs Area */}

                {/* Start References Area */}
                <div className="service-area pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>References</h2>
                                    <p>Some of the wonderful people I have worked with along the way.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ReferenceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="service service__style--2">
                                        <div>
                                            <img className="co-logo" src={val.logo}/>
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.name}</h3>
                                            <h4>{val.company}</h4>
                                            <h5>{val.title}</h5>
                                            <p>{val.phone}</p>
                                            <a href={"mailto: " +val.email}>
                                                <p>{val.email}</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                    <a className="rn-button-style--2 btn-solid" href="https://www.dropbox.com/s/5xef52pw6frjmdd/per-kvanbeck-resume%282023%29.pdf?dl=0"><span>Download resume</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End References Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer homeLink="/" portfolioLink="/portfolio" aboutLink="/about-me" />


            </React.Fragment>
        )
    }
}
export default Experience;