import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiUsers } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class BlogDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Center for Economic Inclusion' />
                {/* Start Header Area  */}
                <div className="active-dark">
                    <Header homeLink="/" portfolioLink="/portfolio" aboutLink="/about-me" projectsLink="/projects" logo="symbol-dark" color="color-black"/>
                </div>
                {/* End Header Area  */}
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--8" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Center for Economic Inclusion</h2>
                                    <br></br>
                                    <h4 className="title theme-gradient">Elevating the narrative of the economic disparity gap</h4>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiUsers />Center for Economic Inclusion</li>
                                        <li><FiClock />2018</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="inner-wrapper">
                                <div className="inner">
                                    <div className="d-flex flex-wrap">
                                        <div className="col-lg-7 col-sm-12">
                                            <h3>Synopsis</h3>
                                            <p>The Center for Economic Inclusion is an organization dedicated to creating a more inclusive economy. CEI’s vision is that by reducing the economic disparity of disenfranchised populations, the entire regional economy will grow. CEI approached Prime Digital Academy to create a disaggregated data dashboard to share their message.</p>
                                            <p>Greater MSP asked the Center for Economic Inclusion to build a disaggregated data dashboard to share their vision of a more inclusive economy. The center wanted to show the reality of the economic conditions for people of color within the greater Minneapolis/St. Paul region. They asked our team to identify the best solution.</p>
                                        </div>
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-4 col-sm-12">
                                            <h3>Methodology</h3>
                                            <ul className="list-style">
                                                <li>inVision app</li>
                                                <li>Secondary research</li>
                                                <li>Sketch</li>
                                                <li>User Interviews</li>
                                                <li>Usability Testing</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="col-lg-12">                               
                                    <div className="blog-single-list-wrapper d-flex flex-wrap">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/projects/cei/interview-2.jpg" alt="Proposed Habitaware high fidelity screen showing journaling feature."/>
                                        </div>
                                        <div className="content">
                                            <h3>Speaking with experts</h3>
                                            <p>Our contact at CEI, John Micevych, provided us with a list of policy makers, regional business leaders, and members of the media. These individuals represented the primary and secondary users of the dashboard CEI wished to create. Our team conducted several user interviews in order to ascertain their needs. As we interviewed individuals we showed them current data dashboard offerings from organizations like Greater MSP, DataUSA.io, and MNCompass.</p>
                                        </div> 
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <p>We asked the individuals to identify each of these dashboards successes, and potential areas for opportunity. Through our discussions we identified three areas for improvement. All users expressed dissatisfaction with overly complex graphing elements and descriptions of data. Users wanted to be told the importance of specific data relationships with supporting, simplified evidence.</p>
                                    <p className="mt--40">All users reported a feeling of helplessness when they viewed dashboards. Even if they understood the data, they reported not knowing what to do with that information. They also questioned what kind of impact they could have on such a large issue, or if their work would make a difference at all.</p>
                                    <br></br>
                                    
                                    <div className="thumbnail">
                                            <video className="w-100" src="/assets/images/projects/cei/cei-dashboard.mp4" loop="loop" autoPlay="autoplay" muted></video>
                                    </div>
                                        <h3>Delievered product</h3>
                                        <p>Knowing that users were looking for far more beyond just a data dashboard, we pivoted to creating a web platform for CEI. This web platform was intended to identify the primary issues faced by the Greater Minneapolis/St. Paul economy via accessible graphs and definitions. But beyond the data, the new platform would identify key action steps that local policy-makers and business leaders could take to help improve conditions.</p>
                                        <p>
Providing these individuals with specific steps and a targetable goal creates an easier buy-in. Going beyond just explaining how bad a situation is, and giving them a path forward. We coupled this with progress tracking metrics for simplified and attainable goals that will result in a more inclusive economy.</p>
                                    <br></br>
                                    <br></br>
                                    <h3>Looking forward</h3>
                                    <p>Our team provided the Center for Economic Inclusion with a solution they could incorporate directly into their current website. We identified what a dashboard would need beyond just data to create a meaningful impact. We outlined the content that will live on each page and the interactivity of the webpages in an annotated wireframe package that was passed to the client. This solution can be easily revamped with new data each year and updated with new success stories from the community. Creating a manageable, yet impactful, solution for the small CEI team allows them to focus on providing great content, with minimal input.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="service-area pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                <h2>Check out my other projects.</h2>
                                    <a className="rn-button-style--2 btn-solid" href="/portfolio"><span>Back to projects</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer homeLink="/" portfolioLink="/portfolio" aboutLink="/about-me" />

            </React.Fragment>
        )
    }
}
export default BlogDetails;