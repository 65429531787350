import React, { Component } from "react";

const PortfolioListContent = [
    {
        image: 'image-4',
        category: 'UX research, Mobile design',
        title: 'Foodsby consumer native app',
        subtitle: 'Foodsby',
        link: '/foodsby'
    },
    {
        image: 'pmx-portfolio',
        category: 'UX research, Visual design',
        title: 'Performance Matrix',
        subtitle: '3M Health Information Systems',
        link: '/performance-matrix'
    },
    {
        image: 'image-5',
        category: 'UX research, System Design',
        title: 'Kins Therapy app',
        subtitle: 'Kins',
        link: '/kins'
    },
    {
        image: 'image-2',
        category: 'Product design, UX research, Mobile design',
        title: 'Habitaware mobile app',
        subtitle: 'Habitaware',
        link: '/habitaware'
    },
    {
        image: 'image-3',
        category: 'UX research, Dashboard, Data visualization',
        title: 'Combatting the economic disparity gap',
        subtitle: 'Center for Economic Inclusion',
        link: '/CEI'
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <a href={value.link}>
                                <div className="thumbnail-inner">
                                    <div className={`thumbnail ${value.image}`}></div>
                                    <div className={`bg-blr-image ${value.image}`}></div>
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <p>{value.category}</p>
                                        <h4>{value.title}</h4>
                                        <h6>{value.subtitle}</h6>
                                        <div className="portfolio-button">
                                            <a className="rn-btn" href={value.link}>View Details</a>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;