import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let
        tab1 = "Skills",
        tab2 = "Awards",
        tab3 = "Experience",
        tab4 = "Education";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/portfolio">User experience design <span> - UI/UX</span></a>
                                                    Specializing in mobile experiences. Always with a holistic lens.
                                                </li>
                                                <li>
                                                    <a href="/portfolio">Web and mobile front-end<span> - Development</span></a>
                                                    SwiftUI, Android Studio, ReactJS (this site!)
                                                </li>
                                                <li>
                                                    <a href="/portfolio">Design Research<span> - Product</span></a>
                                                    Writing, executing, and analyzing exhaustive and iterative testing processes.
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="https://www.dmi.org/page/2019DVA3MDesign">dmi: Design Value Award - 3M Design <span>- Winner</span></a> 2019
                                               </li>
                                               <li>
                                                   <a href="https://vimeo.com/63204198">Minneapolis International Film Festival<span>- Best MN Made</span></a> 2013
                                               </li>
                                               <li>
                                                   <a href="/about-me">Rated #1 by my wife <span>- She's my #1 too</span></a> 2017 - Present
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                                <li>
                                                   <a href="https://www.illudesigns.com">Founder<span> - Illu Designs </span></a> 2021 - Present
                                               </li>
                                               <li>
                                                   <a href="https://www.foodsby.com">Product Designer<span> - Foodsby</span></a> 2019 - 2020
                                               </li>
                                               <li>
                                                   <a href="https://www.3m.com">Senior UX Visual Designer - <span>3M Health Information Systems</span></a> 2018 - 2019
                                               </li>
                                               
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/about-me">UX Design Certificate<span> - Prime Digital Academy, Minneapolis, MN</span></a> 2018
                                               </li>
                                               <li>
                                                   <a href="/about-me">B.A. Psychology<span> - Augsburg University, Minneapolis, MN</span></a> 2011
                                               </li>
                                               <li>
                                                   <a href="/about-me">Study Abroad<span> - University of Auckland, Auckland, NZ</span></a> 2010
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    <br></br>

                                    <a className="rn-button-style--2 btn-solid" href="/about-me">
                                        <span>View more</span>
                                    </a>

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;
