import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiEdit2, FiLayout, FiUsers } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class BlogDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Performance Matrix' />
                {/* Start Header Area  */}
                <div className="active-dark">
                    <Header homeLink="/" portfolioLink="/portfolio" aboutLink="/about-me" projectsLink="/projects" logo="symbol-dark" color="color-black"/>
                </div>
                {/* End Header Area  */}
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--8" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Performance Matrix</h2>
                                    <h4 className="title theme-gradient">A data analytics and performance management solution</h4>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiLayout />Web app design</li>
                                        <li><FiEdit2 />UX Visual & Interaction Design</li>
                                        <li><FiUsers />UX Research</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="inner-wrapper">
                                <div className="inner">
                                    <div className="d-flex flex-wrap">
                                        <div className="col-lg-7 col-sm-12">
                                            <h3>Synopsis</h3>
                                            <p>Performance Matrix identifies actionable insights for Healthcare providers. Though the amount of data analysis and computation was extremely complex, viewing those insights shouldn't be. This was our task, to help reveal and elevate those key insights in a digestible format. Making data visualizations and information architetcture fit the mental model of a wide array of users.</p>
                                        </div>
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-4 col-sm-12">
                                            <h3>Methodology</h3>
                                            <ul className="list-style">
                                                <li>UX Debt calculation</li>
                                                <li>Heuristic analysis</li>
                                                <li>Contextual inquiry</li>
                                                <li>Cognitive walkthrough</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="col-lg-12">
                                    <p>The modern world of healthcare requires a wider array of tools and systems to properly care for, diagnose, and code treatment. All of that before having to deal with the wealth of insurance companies that cover and deal with each treatment differently. With all that health information it is easy to drown in the data. Performance Matrix, or PMX for short, aimed to transform that wealth of data into actionable insights for healthcare providers to reduce costs and overall improve their care efficiency. </p>
                                    <p>I was brought on as a UX Visual Designer to visualize those insights. Along with the expert help of <a href="https://www.linkedin.com/in/ivanmaramis/">Ivan Maramis</a> as Lead Interaction Designer, we set out to develop a dashboard that allowed for rapid broadening and narrowing of scope for a given initiative. This would provide users with an overview while still allowing them to drill into the key source of major inefficiencies to target.</p>
                                    <div className="thumbnail">
                                        <img src="/assets/images/projects/pmx/pmx-03.jpg" alt="Operational overview dashboard for 3M's Performance Matrix"/>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <h3>Primary User</h3>
                                    <p className="mt--40">3M has a long history within the Health Information Systems space. Their coding and billing softwares have been used by healthcare providers across the nation for more than 30 years. With this extensive history and a couple of timely acquisitions they began to move into a space of consulting healthcare providers on payments and processing. This required a large amount of work by a given individual and tracking huge data sets that would become unwieldy. PMX was built to replace the majority of that human data analysis and put the healthcare providers themselves in control of their insights. Primary user group for PMX included hospital CTOs, data analysts, and 3M health care analysts how could act on these opportunity insights to cut costs and improve health care efficiency.</p>
                                    <p>Initially I conducted a heuristic analysis based off the <a href="https://uxpamagazine.org/ux-debt-in-the-enterprise/">UX Debt Calculator</a>, created by Kimberly Dunwoody and Susan Teague Rector. This analysis would help our team define the key areas of impact for visual improvement, pattern consistency and research opportunities.</p>
                                    <br></br>
                                    <br></br>
                                    <h3>Insights</h3>
                                    <p>Through initial analysis it became apparent that the majority of issues arose from inconsistent interaction patterns, a convoluted information architecture, and lack of key stakeholder buy-in for user research. Ivan and I conducted walkthrough scenarios for sales and business development 3M employees. We asked our primary stakeholder to join for the conversations. Participants were asked to walk through the tool as if they were selling or instructing a new customer on how to use the platform. All participants struggled to create a cohesive message by constantly having to jump from page to page and missed complete pages that held some of the information they wished to share, but did not know existed.</p>
                                    <div className="blog-single-list-wrapper d-flex flex-wrap">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/projects/pmx/ui-elements.jpg" alt="A style guide and component library for Performance Matrix."/>
                                            <span>Performance Matrix new visual language and component library.</span>
                                        </div>
                                        <div className="content">
                                            <h3>Plan</h3>
                                            <p>After conducting five walkthrough scenarios and a live storyboarding session with analysts, Ivan and I set to work providing a new lens to share and consume the complex data visualizations required by PMX. This new tool focused on:</p>
                                            <ul className="list-style">
                                                <li>Streamlining navigational structure</li>
                                                <li>Combining data models into simpler visualizations</li>
                                                <li>Consistent interaction patterns</li>
                                                <li>Filtering and data set control</li>
                                                <li>Meaningful insights and context</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <h3>Results</h3>
                                    <p className="mt--25 mt_sm--5">The new system combined a well known and understood visualization by the user base with a stronger filtering option. This allowed users to more rapidly drill down into the key areas of opportunity. Switching navigational structure to opportunity vs. performance allowed users to more easily identify how they had been performing and targets for moving forward.</p>
                                    <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/projects/pmx/pmx-sankey.png" alt="Individual opportunity Sankey on Performance Matrix dashboard used to identify key departments for a health care facility to track."/>
                                            <span>Individual opportunity Sankey used to identify key departments for a health care facility to track.</span>
                                    </div>
                                    <br></br>
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/projects/pmx/planning-board.jpg" alt="Planning board within PMX to track progress on specific opportunites for the health care facility."/>
                                        <span>Planning board for sorting and organizing opportunity efforts under a single initiative to track.</span>
                                    </div>
                                    <br></br>
                                    <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/projects/pmx/monitor-individual.jpg" alt="Planning board within PMX to track progress on specific opportunites for the health care facility."/>
                                            <span>Once plans were committed, users could monitor key metrics to track overall progress.</span>
                                    </div>
                                    <br></br>
                                    <p className="mb--0">We brought the designs back to the users and assessed them on a task completion based cognitive walkthrough. Users were able to more easily navigate between pages. Users also reported that the information architecture allowed for easier association and narrative building when identifying meaningful opportunities.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="service-area pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                <h2>Check out my other projects.</h2>
                                    <a className="rn-button-style--2 btn-solid" href="/portfolio"><span>Back to projects</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer homeLink="/" portfolioLink="/portfolio" aboutLink="/about-me" />

            </React.Fragment>
        )
    }
}
export default BlogDetails;