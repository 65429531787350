import React, { Component } from "react";
import {FaInstagram ,FaUser , FaLinkedinIn} from "react-icons/fa";

const SocialShare = [
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/per-kvanbeck/'},
    {Social: <FaUser /> , link: 'https://perkvanbeck.myportfolio.com/'},
]
class Footer extends Component{
    constructor(props) {
        super(props);
    }
    render(){
        return(
            <React.Fragment>
                <footer id="footer" className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0 bg_color--4">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Want to see more?</span>
                                        <h2>Check out my <br /> creative work</h2>
                                        <a className="rn-button-style--2" target="_blank" href="https://perkvanbeck.myportfolio.com/">
                                            <span>Go to creative site</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                <h4>Quick link</h4>
                                                <ul className="ft-link">
                                                    <li><a href={this.props.homeLink}>Home</a></li>
                                                    <li><a href={this.props.portfolioLink}>Work</a></li>
                                                    <li><a href={this.props.aboutLink}>About me</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Say hello</h4>
                                                <ul className="ft-link">
                                                    <li><a href="mailto:perkvanbeck@gmail.com">perkvanbeck@gmail.com</a></li>
                                                    <li><a href="tel:1-612-205-8423">Call me</a></li>
                                                    <li><a href="https://www.dropbox.com/s/5xef52pw6frjmdd/per-kvanbeck-resume%282023%29.pdf?dl=0">Download resume</a></li>
                                                </ul>

                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © 2020 Per Kvanbeck. All Rights Reserved.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;