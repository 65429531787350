import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiEdit2, FiSmartphone, FiUsers } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class BlogDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Habitaware' />
                {/* Start Header Area  */}
                <div className="active-dark">
                    <Header homeLink="/" portfolioLink="/portfolio" aboutLink="/about-me" projectsLink="/projects" logo="symbol-dark" color="color-black"/>
                </div>
                {/* End Header Area  */}
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--8" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Habitaware</h2>
                                    <br></br>
                                    <h4 className="title theme-gradient">Bringing the user experience of the life-changing app HabitAware to the next level</h4>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiSmartphone />Mobile app design</li>
                                        <li><FiEdit2 />UX Visual & Interaction Design</li>
                                        <li><FiUsers />UX Research</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="inner-wrapper">
                                <div className="inner">
                                    <div className="d-flex flex-wrap">
                                        <div className="col-lg-7 col-sm-12">
                                            <h3>Synopsis</h3>
                                            <p>Every person has faced their own trials and tribulations with self-control. Most of these issues are confronted with a planned effort to minimize exposure, temptation etc. But what if you were completely unaware that you were engaging in the behavior?</p><p> The HabitAware team created a tech wearable and application to help those who suffer from Body-Focused Repeated Behaviors be more aware of their behavior. This simple increase in awareness has helped some individuals keep their BFRBs in check. But Aneela and her team at HabitAware know there is more they can do to. They approached our team to identify the features that would best fit the needs of their users.</p>
                                        </div>
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-4 col-sm-12">
                                            <h3>Methodology</h3>
                                            <ul className="list-style">
                                                <li>Annotated wireframes</li>
                                                <li>Competitive audit</li>
                                                <li>Kano analysis</li>
                                                <li>Stakeholder interviews</li>
                                                <li>User journey map</li>
                                            </ul>
                                            <br></br><br></br>
                                            <h3>Deliverables</h3>
                                                <a href="https://www.dropbox.com/s/4txpmuzhsaswb01/PK-HabitAware.pdf?dl=0"><strong>Recommendations report</strong></a>
                                                <br></br>
                                                <a href="https://www.dropbox.com/s/019bwdfpeko9jkw/per-5.2-journey_map.pdf?dl=0"><strong>User journey map</strong></a>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="col-lg-12">
                                        <h3>Previous functionality</h3>
                                    <p>HabitAware is a lifestyle app to be used in tandem with the company's wearable, Keen. The Keen bracelet tracks the motions of an individual as they engage in their BFRBs. The user trains the device by replicating the actions during an onboarding process. Keen will monitor the users motion and vibrate if it senses the same pattern of movement using a complex array of gyroscopic sensors and algorithms.</p>
                                    <p>The application provides a means of logging engagement points and outlines the user’s time since last engagement with a “streak” counter on the apps main screen. If the user engages in their BFRB the device will buzz, bringing awareness to the behavior. The user can then push a button on the side of the device that will log that data point. When they open the HabitAware app they are able to see past data logs and enter notes for each incident.</p>
                                    
                                    <h3>Suggested improvements</h3>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="thumbnail">
                                                <img src="/assets/images/projects/habitaware/sketch-1.jpg" alt="Preliminary sketch of a dashboard feature for the Habitaware application."/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4"></div>
                                        <div className="col-lg-4">
                                            <div className="thumbnail">
                                                <img src="/assets/images/projects/habitaware/sketch-2.jpg" alt="Preliminary sketch of a overview page to track an individuals progress with Keen's Habitaware bracelet."/>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <p className="mt--40">Being a lean app, the experience offered little in regards to data visualization and feedback. A kano analysis of potential future features for the application purported the importance for visualized data and trends that would allow for stronger self-reflection. Subjects of the survey reported a strong desire to see their progress and track their data points in a more meaningful manner. Utilizing this information, I created a User Journey Map with specific callouts to where I think the user's experience could be improved. I also created a set of high-fidelity prototypes and packaged it into a Annotated Wireframe presentation.</p>
                                    <br></br>
                                    <div className="blog-single-list-wrapper d-flex flex-wrap">
                                        <div className="content">
                                            <h3>Reinvisioned Dashboard</h3>
                                            <p>I began design to address these issues by creating an enhanced dashboard. The main screen provides the user with a quick overview of their status as well as a bit of historical data. Showing the most pertinent elements allows the user to get in, get a strong idea of how they are doing, and get out and on with their life. This minimization of on-screen time was a key factor for Aneela and her team. I carried this mantra through the rest of the suggested feature additions.</p>
                                            <blockquote className="rn-blog-quote">"LOVE accumulated data and easy visualization to help with trends!"</blockquote>
                                        </div>
                                        <div className="col-lg-1"></div>
                                        <div className="thumbnail">
                                            <video className="w-100" src="/assets/images/projects/habitaware/dashboard.mp4" loop="loop" autoPlay="autoplay" muted></video>
                                        </div>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="blog-single-list-wrapper d-flex flex-wrap">
                                        <div className="thumbnail">
                                            <img className="w-100 no-shadow" src="/assets/images/projects/habitaware/app3.png" alt="Proposed Habitaware high fidelity screen showing journaling feature."/>
                                        </div>
                                        <div className="content">
                                            <h3>New journaling feature</h3>
                                            <p>Beyond providing a more easily digestible data set, a journaling feature allows users to give more meaningful data to their behavior tracking. If a user repeatedly sets off their vibration warning by engaging in their BFRB the application would prompt them to take a moment to reflect. These questions focus on identifying the key factors affecting the individual's mood. Asking the user to reflect on their environmental and emotional factors will help the user grow beyond just awareness of their behavior. Users can reflect upon previous journal entries and build a strategy around the techniques that are working best for them.</p>
                                            <blockquote className="rn-blog-quote">"This would add another layer of getting the person out of the moment and calling attention to the phone app rather than behavior."</blockquote>
                                        </div>
                                    </div>
                                    <div className="blog-single-list-wrapper d-flex flex-wrap">
                                        <div className="content">
                                            <h3>Monthly recap</h3>
                                            <p>The journal’s data is also used within the recap feature. Recap shows the users trends to motivate and inspire or indicate when a change may need to be made. It shows the past month’s number of tracked events and the most used tags and locations for high frequency engagement in the user’s repetitive behavior. This feature offers a means for goal tracking and to summarize the large data sets that were prevalent in the app previously. Providing this information in a more digestible format helps the user focus on the most important elements. It also helps the user get the most meaningful interaction without a large amount of data analysis.</p>
                                        </div>
                                        <div className="col-lg-1"></div>
                                        <div className="thumbnail">
                                            <img className="w-100 no-shadow" src="/assets/images/projects/habitaware/app5.png" alt="High fidelity mock up of proposed monthly recap feature for Habitaware."/>
                                        </div>
                                    </div>
                                    <h3>Looking forward</h3>
                                    <p>Many apps today identify ways to make our lives easier, more entertaining, and more fulfilling. For those who suffer from conditions like body-focused repetitive behavior, the Keen device and HabitAware app go beyond the supplementary value of most applications. With these modifications, the HabitAware offering will rise above self-awareness and help their customers along the path to a more successful recovery. The features suggested pave a way for the application to expand into intelligent trend detection and more meaningful therapy technique suggestions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="service-area pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                <h2>Check out my other projects.</h2>
                                    <a className="rn-button-style--2 btn-solid" href="/portfolio"><span>Back to projects</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer homeLink="/" portfolioLink="/portfolio" aboutLink="/about-me" />

            </React.Fragment>
        )
    }
}
export default BlogDetails;