import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import Pagination from "./common/Pagination";
import ProjectsList from "./projects/ProjectsList";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class Projects extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Projects' />

                {/* Start Header Area  */}
                <div className="active-dark">
                    <Header homeLink="/" portfolioLink="/portfolio" aboutLink="/about-me" projectsLink="/projects" logo="symbol-dark" color="color-black"/>
                </div>
                {/* End Header Area  */}
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Dev Projects'}   />
                {/* End Breadcrump Area */}


                {/* Start Blog Area */}
                <div className="rn-blog-area ptb--120 bg_color--1">
                    <div className="container">
                        <ProjectsList />
                        <div className="row mt--20">
                            <div className="col-lg-12">
                                {/* Start Pagination Area */}
                                <Pagination />
                                {/* End Pagination Area */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Area */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default Projects;
