import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiEdit2, FiSmartphone, FiUsers } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class BlogDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Foodsby' />
                {/* Start Header Area  */}
                <div className="active-dark">
                    <Header homeLink="/" portfolioLink="/portfolio" aboutLink="/about-me" projectsLink="/projects" logo="symbol-dark" color="color-black"/>
                </div>
                {/* End Header Area  */}
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--8" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Foodsby mobile app</h2>
                                    <br></br>
                                    <h4 className="title theme-gradient">An opportunity to elevate experience from functional to delightful</h4>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiSmartphone />Mobile app design</li>
                                        <li><FiEdit2 />UX Visual & Interaction Design</li>
                                        <li><FiUsers />UX Research</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="inner-wrapper">
                                <div className="inner">
                                    <div className="d-flex flex-wrap">
                                        <div className="col-lg-7 col-sm-12">
                                            <h3>Synopsis</h3>
                                            <p>Foodsby is a meal delivery service that shifts the paradigm from individual service to a crowd-sourced model. It focuses on grouping orders together for a single delivery to reduce fees and increase profit for restaurants. I was brought in to elevate the native iOS and Android applications. </p>
                                            <p>Foodsby takes a unique perspective on the world of food delivery. While competitors focus on individual delivery from restaurant to consumer, Foodsby shifts the model to grouping orders and stringing deliveries along </p>
                                        </div>
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-4 col-sm-12">
                                            <h3>Methodology</h3>
                                            <ul className="list-style">
                                                <li>Kano anlaysis</li>
                                                <li>Heuristic analysis</li>
                                                <li>Stakeholder interviews</li>
                                            </ul>
                                            <br></br>
                                            <h3>Deliverables</h3>
                                            <a href="https://www.dropbox.com/s/k5bqtcezsbv8m06/Findings%20%26%20Recommendations%20Report-PerKvanbeck.pdf?dl=0"><strong>Recommendations report</strong></a>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <p>Onboarding with Foodsby's mobile product team offered an exciting new challenge. The team had a plethora of features they were excited to add to their mobile experience. These feature ideas came from their own personal talents and experiences, user feedback and some preliminary research into user behaviors. I was asked to assist with prioritization and bringing these ideas to tangible development specifications. First, I asked the entire mobile development team to participate in a collaborative brainstorm session focused on identifying and grouping these feature ideas. Each person wrote down their ideas, explained them, and we live sorted the results. Each team member was then asked to vote on the top features they were interested in seeing in the application.</p>
                                        <p>Once the priority had been set by the team, I went about creating mockups and descriptions for each potential feature. These features would be put in front of users and rated on a scale of desirability based on the <strong><a href="https://sapioresearch.com/kano-analysis#:~:text=The%20Kano%20Model%20is%20an,as%20performance%20and%20excitement%20requirements.">Kano model</a></strong> of analysis. This model allows our research team to get past the surface level desirability of a specific feature. By asking users to rate the features on two scales, how they feel if the feature was present and absent, and then ask for them to apply importance to that feature we can more accurately determine the impact that feature would have on the user experience. The survey reached over 450 participants split between brand new users and users who were consistent Foodsby customers.</p>
                                        <h3>Feature mockups</h3>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="thumbnail">
                                                    <img src="/assets/images/projects/foodsby/foodsby-1.png" alt="Feature card for survey outlining ability to filter available deliveries for Foodsby by food type."/>
                                                </div>
                                            </div>
                                            <div className="col-lg-4"></div>
                                            <div className="col-lg-4">
                                                <div className="thumbnail">
                                                    <img src="/assets/images/projects/foodsby/foodsby-2.png" alt="Feature card for survey outlining enhanced menu item detail."/>
                                                </div>
                                            </div>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <h3>Kano analysis</h3>
                                        <p>With the large number of respondents we were able to confidently follow whatever results may come. After a long sprint of data analysis we were able to identify that all of our suggested features fell into the delighter category. I sat down with individuals from the Android and iOS development teams and asked for their estimated timeline for each feature. Utilizing those estimates combined with the results from the Kano analysis I created a <strong><a href="https://www.dropbox.com/s/k5bqtcezsbv8m06/Findings%20%26%20Recommendations%20Report-PerKvanbeck.pdf?dl=0">recommendation report</a></strong> and presented the results to the team. The three features below aligned best with our business goals, users most desired functionality, and whose impact could be easily verified once implemented.</p>
                                        <div className="blog-single-list-wrapper d-flex flex-wrap">
                                            <div className="thumbnail col-lg-4">
                                                <img className="w-60 no-shadow" src="/assets/images/projects/foodsby/category.png" alt="Proposed Foodsby feature showing be able to navigate available deliveries by cuisine type."/>
                                            </div>
                                            <div className="col-lg-1"></div>
                                            <div className="content col-lg-6">
                                                <h3>Browse by category</h3>
                                                <p>All user groups had type of food as their highest rated consideration for making an order. The kano analysis also had this feature elevated for consistent and new users, with that score higher for new users. Providing this navigation functionality would help users see more variety for a given day and prove a simple method to add minimal imagery within the application.</p>
                                                <blockquote className="rn-blog-quote">"Addition of this feature would make me more likely to order from restaurants that I currently ignore due to assumptions about the type of food they offer (based on first few sections of menu viewed)." - Survey participant</blockquote>
                                            </div>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="blog-single-list-wrapper d-flex flex-wrap">
                                            <div className="content">
                                                <h3>Favorited items</h3>
                                                <p>Elevating user favorited items offers an opportunity for customization and optimization. This feature allows users to know their favorite dish is delivering on any given day. Users felt this feature would speed up their ordering process, while ensuring they get exactly what they want.</p>
                                                <blockquote className="rn-blog-quote">"One of the biggest things I appreciate with Foodsby is speed and confidence, so this feels pretty important to me." - Survey participant</blockquote>
                                            </div>
                                            <div className="col-lg-2"></div>
                                            <div className="thumbnail col-lg-4">
                                                <img className="w-100 no-shadow" src="/assets/images/projects/foodsby/favorites.png" alt="High fidelity mock up of proposed monthly recap feature for Habitaware."/>
                                            </div>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="blog-single-list-wrapper d-flex flex-wrap">
                                            <div className="thumbnail col-lg-4">
                                                <img className="w-60 no-shadow" src="/assets/images/projects/foodsby/enhanced.png" alt="Proposed Foodsby feature showing be able to navigate available deliveries by cuisine type."/>
                                            </div>
                                            <div className="col-lg-1"></div>
                                            <div className="content col-lg-6">
                                                <h3>Enhanced item detail</h3>
                                                <p>Both new and old users saw this functionality to improve their performance and elevate their ordering experience. This feature allows for users to view items that can be made gluten free, vegetarian or vegetarian. Quick tagging specific elements within the menus allows users to make more informed decisions about what they are ordering.</p>
                                                <blockquote className="rn-blog-quote">"As a vegetarian, it can be very frustrating scrolling through a long list of options and then trying to read all of the ingredients to figure out if it's... even an option for me." - Survey participant</blockquote>
                                            </div>
                                        </div>
                                        <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                            <h3>See the full write up here</h3>
                                            <a className="rn-button-style--2 btn-solid" href="https://www.dropbox.com/s/k5bqtcezsbv8m06/Findings%20%26%20Recommendations%20Report-PerKvanbeck.pdf?dl=0"><span>Recommendations report</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                <div className="service-area pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                <h2>Check out my other projects.</h2>
                                    <a className="rn-button-style--2 btn-solid" href="/portfolio"><span>Back to projects</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer homeLink="/" portfolioLink="/portfolio" aboutLink="/about-me" />

            </React.Fragment>
        )
    }
}
export default BlogDetails;